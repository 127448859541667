// components/Layout.js
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import rectangle from "../assets/Rectangle 7.svg";
import LanguageSetting from "./LanguageSetting";
import React from "react";

export default function LandingLayout({children}) {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const goBack = () => {
        navigate(-1); // -1 表示返回上一頁
    };
    return (
        <div
            className="flex flex-col h-full items-center justify-center gap-5 px-0  relative bg-white [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] overflow-y-auto overflow-x-hidden">
                <div
                    className="absolute top-2 left-0 flex items-center gap-5 mt-4 ml-4"
                    onClick={goBack}
                >
                    <img style={{transform: "rotate(90deg)"}}
                         className="relative w-[6.86px] h-[12.31px] ml-[10px]"
                         alt="Rectangle"
                         src={rectangle}
                    />
                    <div
                        className="relative w-[81px] mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-[#7f7f7f] text-sm tracking-[0] leading-[normal]">
                        {t('back')}
                    </div>


            </div>

            {/* Main Content */}
            <main className="container mx-auto p-4">
                <LanguageSetting/>
                <div className="flex flex-col items-center justify-center h-full">

                    {children} {/* This will render the page-specific content */}
                </div>
            </main>

        </div>
    );
}
