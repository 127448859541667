import rectangle from "../assets/Rectangle 7.svg"
import menu from "../assets/mingcute_menu-line.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function MainLayout({children}) {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const goBack = () => {
        navigate(-1); // -1 表示返回上一頁
    };
    return (
        <div
            className="flex flex-col h-full items-center p-5 relative bg-variable-collection-01 overflow-y-auto overflow-x-hidden">
            {/*<div*/}
            {/*    className="absolute w-[116px] h-[116px] top-[-42px] right-[-46px] bg-variable-collection-04 rounded-[58px] border-[5px] border-solid border-variable-collection-02"/>*/}
            <div className="flex w-full h-[30px] items-start justify-between relative mb-[30px]">
                <div className="flex w-[210.57px] items-center gap-5 relative" onClick={goBack}>
                    <img style={{transform: "rotate(90deg)"}}
                         className="relative w-[6.86px] h-[12.31px] ml-[-0.71px]"
                         alt="Rectangle"
                         src={rectangle}
                    />
                    <div
                        className="relative w-[81px] mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-[#7f7f7f] text-sm tracking-[0] leading-[normal]">
                        {t('back')}
                    </div>
                </div>

            </div>

            {/* Main Content */}
            <main>
                {children} {/* This will render the page-specific content */}
            </main>

        </div>
    );
}

