import React, {useEffect, useState} from "react";
import {CurrencyDropdown} from "./CurrencyDropdown";
import fillIcon from "../assets/ri_reset-left-fill.svg"
import transferIcon from "../assets/bx_transfer-alt.svg"
import {useTranslation} from "react-i18next";

export const ExchangeCalculator = ({exchangeData}) => {
    const [fromCurrencyCodes, setFromCurrencyCodes] = React.useState([]);
    const [currentCode, setCurrentCode] = useState('');
    const [toCurrentCodes, setToCurrentCodes] = useState([]);
    const [exchangeCode, setExchangeCode] = useState('');
    const [amount, setAmount] = useState('');
    const [exchangeAmount, setExchangeAmount] = useState('');
    const { t, i18n } = useTranslation();

    const [rate, setRate] = useState('');

    useEffect(() => {
        if (exchangeData.length > 0) {
            const currencyCodes = exchangeData.map(x => x.currency.code)
            setFromCurrencyCodes([...new Set(currencyCodes)]);
            setCurrentCode(currencyCodes[0]);
        }
    }, [exchangeData]);

    useEffect(() => {
       const  data=exchangeData.find(x => x.currency.code === currentCode);
       if(data){
           const toCurrencyCodes =data.exchangeRates.map(x=>x.toCurrencyCode)
           setToCurrentCodes(toCurrencyCodes);
       }
    }, [currentCode]);
    useEffect(() => {
        const calculatedAmount = amount * rate;
        const roundedUpAmount = Math.ceil(calculatedAmount * 100) / 100; // Round up to 2 decimal places
        setExchangeAmount(roundedUpAmount);
    }, [amount,rate]);
    useEffect(() => {
        if(currentCode && exchangeCode){
            const  data=exchangeData.find(x => x.currency.code === currentCode);
            console.log("data",data)
            if(data){
                const exchangeRate =data.exchangeRates.find(x => x.toCurrencyCode === exchangeCode);
                console.log("exchangeRate",exchangeRate)

                setRate(exchangeRate.rate);
            }
        }

    }, [currentCode,exchangeCode]);

    return (
        <div
            className="relative w-[370px] h-[318px] bg-white rounded-[20px] overflow-hidden border-[5px] border-solid border-variable-collection-02 [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]">
            <div className="absolute w-[397px] h-[120px] top-[-51px] left-5">
                <div
                    className="absolute w-[69px] h-[120px] top-0 left-[277px] bg-[url('./src/assets/star-4.svg')] bg-[100%_100%]"/>
                <div
                    className="absolute w-[330px] top-[76px] left-0 [font-family:'Inter-Bold',Helvetica] font-bold text-sm text-variable-collection-05 tracking-[0] leading-[normal]">
                    {t('exchangeCalculator')}
                </div>
                <img
                    className="absolute w-6 h-6 top-[66px] left-[306px]"
                    alt="Ri reset left fill"
                    src={fillIcon}
                />
            </div>

            <div
                className="flex w-[330px] items-center justify-between top-[150px] left-5 bg-[#fff7e4] rounded-[5px] h-[47px] absolute"
            >
                <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full h-full bg-transparent outline-none border-none text-variable-collection-05 text-[14px] [font-family:'Inter-Regular',Helvetica] px-2"
                    placeholder={t('amountPlaceholder')}
                />
                <div
                    className="text-[14px] text-variable-collection-05 [font-family:'Inter-Regular',Helvetica] pr-2"
                >
                    {currentCode}
                </div>
            </div>
            <div
                className="flex w-[330px] items-center justify-between p-2.5 top-[217px] left-5 bg-[#fff7e4b2] rounded-[5px] h-[47px] absolute">
                <div
                    className="flex items-center w-full h-7 mt-[-1.50px] [font-family:'Inter-Regular',Helvetica] font-normal text-[14px] text-variable-collection-05 tracking-[0] leading-[normal]">
                    {exchangeAmount}
                </div>
                <div
                    className="flex items-center justify-end w-[118px] h-7 mt-[-1.50px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[14px] text-right tracking-[0] leading-[normal]">
                    {exchangeCode}
                </div>
            </div>

            <div className="w-[330px] top-[83px] left-5 h-[47px] absolute">
                <CurrencyDropdown
                    key={`from-${currentCode}`}
                    className="!left-0 !absolute !top-0"
                    property1="default"
                    rectangle="rectangle-7-2.svg"
                    rectangleClassName="!h-[7px] !top-[11px]"
                    currencyCodes={fromCurrencyCodes}
                    currentCode={currentCode}
                    onClick={(value) => {
                        setCurrentCode(value)
                    }}
                />
                <img
                    className="absolute w-5 h-5 top-3.5 left-[155px]"
                    alt="Bx transfer alt"
                    src={transferIcon}
                />
                <CurrencyDropdown
                    key={`to-${exchangeCode}`}
                    className="!left-[190px] !absolute !top-0"
                    property1="default"
                    rectangle="rectangle-7-3.svg"
                    rectangleClassName="!h-[7px] !top-[11px]"
                    currencyCodes={toCurrentCodes}
                    currentCode={exchangeCode}
                    onClick={(value) => {
                        setExchangeCode(value)
                    }}
                />
            </div>
        </div>
    );
};
