import PropTypes from "prop-types";
import React, { useReducer } from "react";

export const DropdownWrapper = ({ property1, className, code, onclick }) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });

    return (
        <div
            className={`w-[140px] flex items-center px-2.5 py-0 justify-between relative ${
                state.property1 === "variant-2" ? "bg-variable-collection-02" : ""
            } ${className}`}
            onMouseLeave={() => {
                dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
                dispatch("mouse_enter");
            }}
            onClick={onclick}
        >
            <div className="[font-family:'Inter-Regular',Helvetica] w-[118px] mt-[-1.00px] tracking-[0] text-[14px] text-variable-collection-05 h-7 font-normal leading-[normal] relative">
                {code}
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "variant-2",
            };

        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };

        default:
            return state;
    }
}

DropdownWrapper.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
    className: PropTypes.string,
};
