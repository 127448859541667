import smallLogo from "../assets/logo-orange.png";
import MainLayout from "../components/MainLayout";
import {useTranslation} from "react-i18next";
import fb from "../assets/fb.svg"
import ig from "../assets/ig.svg"
import tg from "../assets/tg.svg"
export default function ContactUsPage() {

    const {t, i18n} = useTranslation();


    return (
        <MainLayout>
            <div
                className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="w-[78px] h-[59px]"/>
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                    {t('contactUs')}
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-5 relative tracking-[0]">
                    {t('contactUsMsg')}
                </div>
                <div className="inline-flex items-center gap-[27px] relative flex-[0_0_auto]">
                    <div
                        className="flex flex-col w-[90px] h-[90px] items-center justify-center gap-[15px] relative rounded-[5px] overflow-hidden border border-solid border-[#ff9b6380]">
                        <img className="relative w-[30px] h-[30px]" alt="Ic baseline telegram"
                             src={tg}/>
                        <div
                            className="relative self-stretch [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-center tracking-[0] leading-[normal]">
                            Telegram
                        </div>
                    </div>
                    <div
                        className="flex flex-col w-[90px] h-[90px] items-center justify-center gap-[15px] relative rounded-[5px] overflow-hidden border border-solid border-[#ff9b6380]">
                        <img className="relative w-[30px] h-[30px]" alt="Uim instagram alt" src={ig}/>
                        <div
                            className="relative self-stretch [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-center tracking-[0] leading-[normal]">
                            Instagram
                        </div>
                    </div>
                    <div
                        className="flex flex-col w-[90px] h-[90px] items-center justify-center gap-[15px] relative rounded-[5px] overflow-hidden border border-solid border-[#ff9b6380]">
                        <img className="relative w-[27px] h-[27px]" alt="Entypo social" src={fb}/>
                        <div
                            className="relative self-stretch [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-center tracking-[0] leading-[normal]">
                            Facebook
                        </div>
                    </div>
                </div>


            </div>

        </MainLayout>
    );
}
