import React, {useState} from "react";
import vector3 from "../assets/Vector 2.png";
import Flatpickr from 'react-flatpickr';
import {useTranslation} from "react-i18next";

export const TransactionFilter = ({isOpen, onClose, filterPayload, handleSubmit}) => {
    const [selectedStatuses, setSelectedStatuses] = useState([]); // Array of selected statuses
    const [startDate, setStartDate] = useState(filterPayload.fromDate); // Start date
    const [endDate, setEndDate] = useState(filterPayload.toDate); // End date
    const {t, i18n} = useTranslation();
    const transactionStatuses = ["pending", "success", "reject"];

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    }
    const toggleStatusSelection = (status) => {
        setSelectedStatuses((prev) =>
            prev.includes(status) ? prev.filter((s) => s !== status) : [...prev, status]
        );
    };
    const confirmSelection = () => {
        // Prepare payload
        const payload = {
            status: selectedStatuses,
            fromDate: startDate,
            toDate: endDate,
        };

        // Call the API or pass the data to a parent function
        handleSubmit(payload);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="absolute inset-0 bg-black/60 backdrop-blur-sm" onClick={handleOutsideClick}></div>
            <div
                className="flex flex-col w-[390px] h-[357px] items-center justify-center gap-2.5 p-5 relative bg-white rounded-[20px] overflow-hidden">
                <div
                    className="relative w-[350px] h-[23px] font-bold text-variable-collection-05 text-base text-center">
                    {t('filterTitle')}
                </div>


                <img className="relative w-[350px] h-px" alt="Divider" src={vector3}/>


                <div className="relative self-stretch h-[23px] font-bold text-variable-collection-05 text-xs">
                    {t('transactionStatus')}
                </div>

                <div className="flex w-[350px] items-start justify-between">
                    {transactionStatuses.map((label, index) => (
                        <button
                            key={index}
                            onClick={() => toggleStatusSelection(label)} // Toggle selection
                            className={`flex w-[100px] h-10 items-center justify-around px-3.5 py-0 rounded-[5px] border border-solid border-[#e8e8e8] ${
                                selectedStatuses.includes(label)
                                    ? "[background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]  text-white"
                                    : " text-[#62554e]"
                            }`}
                        >
                            <div className="font-medium text-[10px] text-center"> {t(label)}</div>
                        </button>
                    ))}
                </div>


                <div className="relative self-stretch h-[23px] font-bold text-variable-collection-05 text-xs">
                    {t('dateRange')}
                </div>

                <div className="flex w-[350px] items-start gap-2.5">
                    <div className="w-[178px] h-[23px] font-normal text-variable-collection-05 text-xs">
                        {t('startDate')}
                    </div>
                    <div className="w-[55px] h-[23px] font-normal text-variable-collection-05 text-xs">
                        {t('endDate')}
                    </div>
                </div>

                <div className="relative w-[350px] h-[47px]">

                    <div

                        className={`flex w-40 h-[47px] items-center justify-around px-2.5 py-2 absolute top-0 
                           left-0 bg-variable-collection-15 rounded-[5px]`}
                    >
                        <Flatpickr
                            value={startDate}
                            onChange={(date) => setStartDate(date[0]?.toISOString() || "")} // Update start date
                            options={{dateFormat: 'Y-m-d'}}
                            placeholder="yyyy/mm/dd"
                            className="ml-2 p-2 bg-variable-collection-15 border-none "
                        />

                    </div>
                    <div

                        className={`flex w-40 h-[47px] items-center justify-around px-2.5 py-2 absolute top-0
                            left-[190px]  bg-variable-collection-15 rounded-[5px]`}
                    >
                        <Flatpickr
                            value={endDate}
                            onChange={(date) => setEndDate(date[0]?.toISOString() || "")} // Update end date
                            placeholder="yyyy/mm/dd"
                            options={{dateFormat: 'Y-m-d'}}
                            className="ml-2 p-2 bg-variable-collection-15 border-none "
                        />

                    </div>

                </div>
                <div onClick={confirmSelection}
                     className="w-[150px] flex items-center justify-center gap-2.5 px-3.5 py-0 rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] relative  flex-[0_0_auto]"
                >
                    <div
                        className="mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] leading-[50px] relative  text-center tracking-[0]"
                    >
                        {t('confirmButton')}
                    </div>
                </div>
            </div>
        </div>
    );
};
