import { IoHomeOutline } from "react-icons/io5";
import { FaRegListAlt } from "react-icons/fa";
import { BsCurrencyExchange } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../services/authContext";

export default function NavBar() {
    const [activeTab, setActiveTab] = useState('home');
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();

    const handleClick=(page)=>{
        if(!isLoggedIn && page !=="home"){
            navigate("/login")
        }
        else if(page !== activeTab){
            setActiveTab(page)
            navigate(`/${page}`);
        }
    }
    return (
        <div className="fixed bottom-0 left-0 right-0 h-[60px] bg-amber-50 shadow-md flex justify-around items-center">
            <div className="flex flex-col items-center" onClick={() => handleClick('home')}>
                <IoHomeOutline
                    className={activeTab === 'home' ? 'text-orange-400' : 'text-gray-500'}
                    size={24}
                />
                <span className={ `[font-family:'Inter-Regular',Helvetica] ${activeTab === 'home' ? 'text-orange-400' : 'text-gray-500'}`}>Home</span>

            </div>
            <div className="flex flex-col items-center" onClick={() => handleClick('transactions')}>
                <FaRegListAlt
                    className={activeTab === 'transactions' ? 'text-orange-400' : 'text-gray-500'}
                    size={24}
                />
                <span className={ `[font-family:'Inter-Regular',Helvetica] ${activeTab === 'orders' ? 'text-orange-400' : 'text-gray-500'}`}>Orders</span>
            </div>
            <div
                className="flex flex-col items-center"
                onClick={() => handleClick('exchange')}
            >
                <BsCurrencyExchange
                    className={activeTab === 'exchange' ? 'text-orange-400' : 'text-gray-500'}
                    size={24}
                />
                <span className={ `[font-family:'Inter-Regular',Helvetica] ${activeTab === 'exchange' ? 'text-orange-400' : 'text-gray-500'}`}>Exchange</span>
            </div>
            <div
                className="flex flex-col items-center"
                onClick={() => handleClick('profile')}
            >
                <CgProfile
                    className={activeTab === 'profile' ? 'text-orange-400' : 'text-gray-500'}
                    size={24}
                />
                <span className={ `[font-family:'Inter-Regular',Helvetica] ${activeTab === 'profile' ? 'text-orange-400' : 'text-gray-500'}`}>Profile</span>
            </div>
        </div>
    );
}
