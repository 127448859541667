import PropTypes from "prop-types";
import React, { useReducer } from "react";

export const FromCurrencyRate = ({ property1, className, text, onClick }) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });

    return (
        <div
            className={`border border-solid w-[110px] flex items-center gap-2.5 h-10 rounded-[5px] justify-center relative mb-2 ${
                state.property1 === "variant-2"
                    ? "border-variable-collection-02"
                    : "border-[#f2f2f2]"
            } ${
                state.property1 === "variant-2"
                    ? "bg-variable-collection-01"
                    : ""
            } ${className}`}
            onMouseLeave={() => dispatch("mouse_leave")}
            onMouseEnter={() => dispatch("mouse_enter")}
            onClick={onClick}
        >
            <div className="w-[100px] flex flex-col items-start gap-2.5 h-[30px] justify-center relative">
                <div className="[font-family:'Inter-Regular',Helvetica] self-stretch mt-[-11.00px] tracking-[0] text-[15px] text-variable-collection-05 font-normal text-center mb-[-9.00px] leading-[50px] relative">
                    {text}
                </div>
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "variant-2",
            };
        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };
        default:
            return state;
    }
}

FromCurrencyRate.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
};
