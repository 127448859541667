// PopupContext.js
import React, { createContext, useContext, useState } from 'react';
import {useNavigate} from "react-router-dom";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const showPopup = () => {
        setIsOpen(true);
    };

    const hidePopup = () => {
        setIsOpen(false);
    };

    return (
        <PopupContext.Provider value={{ isOpen, showPopup, hidePopup }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);
