import Balance from "../components/Balance";
import Banner from "../components/Banner";
import QuickAction from "../components/QuickAction";
import cat from "../assets/cat.png";
import {ExchangeRateTable} from "../components/ExchangeRateTable";
import DashboardLayout from "../components/DashboardLayout";
import Referral from "../components/Referral";
import {ExchangeCalculator} from "../components/ExchangeCalculator";
import {useEffect, useState} from "react";
import {getExchangeRates} from "../services/apiService";
import {useAuth} from "../services/authContext";
import LanguageSetting from "../components/LanguageSetting";
import NavBar from "../components/NavBar";
import logo from "../assets/logo-orange.png";

export default function Dashboard() {
    const [exchangeData, setExchangeData] = useState([]);
    const {isLoggedIn} = useAuth();

    useEffect(() => {
        // 獲取匯率資料
        const fetchExchangeRates = async () => {
            try {
                const response = await getExchangeRates();
                setExchangeData(response);
            } catch (error) {
                console.error("Error fetching exchange rates:", error);
            }
        };

        fetchExchangeRates();
    }, []);
    return (
        <DashboardLayout>
            <LanguageSetting></LanguageSetting>
            <div
                className="flex flex-col items-center gap-5 px-0 py-5 relative bg-white rounded-b-[20px] overflow-hidden border-b-[5px] [border-bottom-style:solid] border-[#ff9b63] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]">
                <img style={{filter: 'blur(4px)'}}
                     className="absolute z-999  w-[468px] h-[551px] top-[17px] left-[151px] object-cover blur-sm opacity-50 "
                     src={cat}/>
                <div className="flex w-full h-[54px] items-start justify-between relative">
                    {/*<div*/}
                    {/*    className="absolute w-[67px] h-[120px] top-[-65px] right-0 bg-[url('./src/assets/star-4.svg')] bg-[100%_100%]"/>*/}
                    <div
                        className="relative w-[81px] mt-[-1.00px] ml-3 [font-family:'Inter-Bold',Helvetica] font-bold text-[#ff9b63] text-sm tracking-[0] leading-[normal]">
                        <img className="w-[81px] h-[53px]" src={logo}/>

                    </div>
                    {/*<img className="relative w-12 h-6 " src={menu}/>*/}
                </div>
                <Balance></Balance>
                <div className="w-[350px] h-px">
                    <svg width="350" height="2" viewBox="0 0 350 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H350" stroke="#62554E" strokeWidth="0.5"/>
                    </svg>
                </div>

                <Banner></Banner>
                <QuickAction></QuickAction>
            </div>
            <div
                className="flex flex-col items-center gap-5 px-0 py-5 relative bg-variable-collection-02  overflow-hidden border-b-[5px] ">
                <ExchangeRateTable exchangeData={exchangeData}></ExchangeRateTable>
                <ExchangeCalculator exchangeData={exchangeData}></ExchangeCalculator>
                <Referral></Referral>
            </div>
            <NavBar />
        </DashboardLayout>
    );
}
