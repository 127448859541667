import copyIcon from "../assets/ph_copy.svg";
import {useAuth} from "../services/authContext";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getProfile} from "../services/apiService";
import fbIcon from "../assets/facebook.png"
import tgIcon from "../assets/telegram.png"
import waIcon from "../assets/whatsapp.png"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

export default function Referral() {
    const {isLoggedIn} = useAuth();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({name: ''});
    const [referralUrl, setReferralUrl] = useState('');
    const {t} = useTranslation();


    const handleFacebookShare = () => {
        const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            referralUrl
        )}&quote=${encodeURIComponent(referralUrl)}`;

        window.open(fbShareUrl, '_blank');
    };

    const handleTelegramShare = () => {
        const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
            referralUrl
        )}&text=${encodeURIComponent(referralUrl)}`;

        window.open(telegramShareUrl, '_blank');
    };
    const handleWhatsAppShare = () => {
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            referralUrl
        )}%20${encodeURIComponent(referralUrl)}`;

        window.open(whatsappShareUrl, '_blank');
    };
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(profile.invitationCode);
            toast.success(t('copiedToClipboard'), {
                position: 'bottom-center',
                autoClose: 2000,
            });
        } catch (err) {
            toast.error(t('copiedFailed'), {
                position: 'bottom-center',
                autoClose: 2000,
            });
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            const fetchProfile = async () => {
                try {
                    const profile = await getProfile();

                    setProfile(profile);
                    setReferralUrl(`https://mercury-dev-189300884983.asia-east1.run.app/signup?referral=${profile.invitationCode}`)
                } catch (error) {
                    console.error('Error fetching profile:', error);
                }
            };

            fetchProfile();
        }
    }, [])
    return (
        <div
            className="flex flex-col w-[370px]  items-center gap-2.5 p-5 relative bg-white rounded-[20px] overflow-hidden border border-solid border-variable-collection-02 mb-10">
            <div
                className="h-[27px] mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-sm leading-[normal] relative w-[330px] text-variable-collection-05 tracking-[0]">{t('ReferralTitle')}
            </div>
            <div
                className="[font-family:'Inter-Regular',Helvetica] font-normal text-[15px] leading-5 relative w-[330px] text-variable-collection-05 tracking-[0]"> {t('ReferralContent')}
            </div>
            {isLoggedIn ? <>
                <div className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">

                    <div
                        className={`w-[60px] flex flex-col items-center gap-2 relative`}
                        onClick={handleFacebookShare}
                    >
                        <div
                            className=" w-[60px] flex flex-col items-center gap-2.5 px-2.5 py-[18px] h-[60px] rounded-[10px] justify-center relative">
                            <img className="h-[60px] w-[60px] " alt="FB" src={fbIcon}/></div>
                        <div
                            className="[font-family:'Inter-Regular',Helvetica] self-stretch tracking-[0] text-[15px] text-variable-collection-05 font-normal text-center leading-[normal] relative">
                            Facebook
                        </div>

                    </div>
                    <div
                        className={`w-[60px] flex flex-col items-center gap-2 relative`}
                        onClick={handleTelegramShare}
                    >
                        <div
                            className=" w-[60px] flex flex-col items-center gap-2.5 px-2.5 py-[18px] h-[60px] rounded-[10px] justify-center relative">
                            <img className="h-[60px] w-[60px] " alt="TG" src={tgIcon}/></div>
                        <div
                            className="[font-family:'Inter-Regular',Helvetica] self-stretch tracking-[0] text-[15px] text-variable-collection-05 font-normal text-center leading-[normal] relative">
                            Telegrem
                        </div>

                    </div>
                    <div
                        className={`w-[60px] flex flex-col items-center gap-2 relative`}
                        onClick={handleWhatsAppShare}
                    >
                        <div
                            className=" w-[60px] flex flex-col items-center gap-2.5 px-2.5 py-[18px] h-[60px] rounded-[10px] justify-center relative">
                            <img className="h-[60px] w-[60px]" alt="WS" src={waIcon}/></div>
                        <div
                            className="[font-family:'Inter-Regular',Helvetica] self-stretch tracking-[0] text-[15px] text-variable-collection-05 font-normal text-center leading-[normal] relative">
                            Whatsapp
                        </div>

                    </div>
                </div>
                <div
                    className="h-[27px] [font-family:'Inter-Medium',Helvetica] font-medium text-[15px] leading-[normal] relative w-[330px] text-variable-collection-05 tracking-[0]">
                    {t('referralCode')}
                </div>
                <div onClick={handleCopy}
                     className="w-[330px] justify-between px-3.5 py-0 flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8] flex items-center relative">
                    <div
                        className="relative w-fit mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center tracking-[0] leading-[50px] whitespace-nowrap"> {profile.invitationCode}
                    </div>
                    <img className="relative w-5 h-5" alt="Copy" src={copyIcon}/>
                </div>
                <ToastContainer/>
            </> : <div onClick={() => {
                navigate("/login")
            }}
                       className="flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]">
                <div
                    className="w-40 mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]">
                    {t('shareButton')}
                </div>
            </div>}


        </div>
    );
}
