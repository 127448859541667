import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import LandingLayout from "../components/LandingLayout";
import {login} from "../services/apiService";
import {useAuth} from "../services/authContext";
import InputField from "../components/InputField";
import { useTranslation } from 'react-i18next';

import logo_cn from "../assets/HHlogo-cn.png";
import smallLogo from "../assets/logo-orange.png";

function LoginPage() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const {login: authLogin} = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const inputFields = [
        {
            label:  t('userName'),
            placeholder: t('userNamePlaceholder'),
            value: username,
            onChange: (e) => setUsername(e.target.value)
        },
        {
            label: t('pw'),
            placeholder: t('psPlaceholder'),
            type: "password",
            value: password,
            onChange: (e) => setPassword(e.target.value),
            onKeyDown:  async (event) => {

                if (event.key === 'Enter' && username) {
                    await handleSubmit(event);
                }
            }
        },
    ];
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload on form submit
        setLoading(true);

        // Input validation
        if (!username || !password) {
            setError(t('fillAllFields'));
            return;
        }

        setError(''); // Clear the error if inputs are valid

        try {
            const data = await login(username, password);

            if(data.status === 400){
                setError(t(data.title));
            }
            else if (data.token) {
                authLogin(data.token);
                navigate("/dashboard");
            } else {
                setError(t('loginInputWrong'));
            }

        } catch (error) {
            console.error('Error during login:', error);
            setError(t('loginInputWrong'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <LandingLayout>

            <div
                className="flex flex-col w-[370px] items-center justify-center gap-5 px-5 py-3 relative bg-white rounded-[20px]">
                <img className="relative w-[78px] h-[59px]" alt="Group" src={smallLogo}/>
                <div
                    className="relative w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center tracking-[0] leading-[normal]">
                    {t('login')}
                </div>

                {inputFields.map((field, index) => (
                    <InputField key={index} {...field} />
                ))}
                {error &&
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>

                    </div>}
                <div
                    onClick={!loading ? handleSubmit : null}  // Disable click when loading
                    className={`flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    <div
                        className="w-[330px] mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]"
                    >
                        {loading ? t('processing') : t('login')}
                    </div>
                </div>


                <div className="justify-end flex w-[330px] items-center gap-2.5 relative flex-[0_0_auto]">
                    <p className="w-[2000px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        <span className="text-[#61554e]">{t('registerReminderMsg')}?&nbsp;&nbsp;</span>
                        <a href="/signup" className="text-[#ff9b63] underline">{t('registerLink')}</a>
                    </p>
                </div>
            </div>
            <div
                className="absolute bottom-12 w-fit [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-01 text-[15px] tracking-[0] leading-[normal]">
                <img className="w-[106px] h-[50px]" src={logo_cn}/>

                <div>全球換匯平台</div>
            </div>
        </LandingLayout>
    );
}

export default LoginPage;
