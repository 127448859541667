const CACHE_DURATION = 5 * 60 * 1000;

const getToken = () => {
    return localStorage.getItem('token');
};
const getApiUrl = () => {
    const env= process.env.REACT_APP_NODE_ENV;
    console.log("env",env)
    console.log("url",process.env.REACT_APP_API_URL)

    if (env === 'production') {
        return `${window.location.protocol}//api.${(window.location.hostname)}`;
    } else {
        return process.env.REACT_APP_API_URL;
    }
};

const memoryCache = {};

const apiRequest = async (url, method = 'GET', body = null, requiresAuth = false, cache = false) => {
    const cacheKey = `${method}:${url}:${getToken()}`;

    if (cache && memoryCache[cacheKey]) {
        const cachedData = memoryCache[cacheKey];
        const currentTime = new Date().getTime();
        if (currentTime - cachedData.timestamp < CACHE_DURATION) {
            return cachedData.data;
        } else {
            delete memoryCache[cacheKey]; // Remove expired data
        }
    }

    const headers = {
        'Content-Type': 'application/json',
    };

    // 如果需要身份驗證，將 token 添加到 Authorization 標頭中
    if (requiresAuth) {
        const token = getToken();
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        } else {
            throw new Error('No token available, please login first.');
        }
    }

    const options = {
        method,
        headers,
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(`${getApiUrl()}/api${url}`, options);
        if (!response.ok) {
            if (response.status === 401 && url !== '/Auth/login') {
                localStorage.removeItem('token');
                window.location = '/login';
            }
        }
        if (cache) {
            const data = await response.json();
            memoryCache[cacheKey] = {
                timestamp: new Date().getTime(),
                data: data
            };
            return data;
        }


        return response;
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
};
export const login = async (username, password) => {
    const response = await apiRequest('/Auth/login', 'POST', {username, password});
    return await response.json();
};
export const register = async (username, email, password, referralCode) => {
    return await apiRequest('/Auth/register', 'POST', {username, email, password, referralCode});
};
export const checkName = async (username) => {
    return await apiRequest('/Auth/CheckUsername', 'POST', {username});
};

export const changePassword = async (oldPassword, newPassword) => {
    const response = await apiRequest('/Auth/UpdatePassword', 'POST', {oldPassword, newPassword}, true);
    return await response.json();

};
export const sendOtp = async (username, areaCode, phone) => {
    return await apiRequest('/Auth/sendOtp', 'POST', {username, areaCode, phone});
};
export const verifyPhone = async (username, token) => {
    return await apiRequest('/Auth/verifyPhone', 'POST', {username, token});
};

export const verifyEmail = async (username, token) => {
    return await apiRequest('/Auth/verifyEmail', 'POST', {username, token});
};
export const getProfile = async () => {
  return  await apiRequest('/account/GetProfile', 'GET', null, true, true); // `true` 表示此請求需要身份驗證


};
export const getExchangeRates = async () => {
    return await apiRequest('/ExchangeRates', 'GET', null, false, true); // `true` 表示此請求需要身份驗證


};
export const getCurrencies = async () => {
   return  await apiRequest('/Currencies', 'GET', null, false, true); // `true` 表示此請求需要身份驗證


};
// 取得帳戶餘額
export const getWallets = async () => {
    const response = await apiRequest('/account/GetWallets', 'GET', null, true); // `true` 表示此請求需要身份驗證
    return await response.json();

};

// 取得帳戶餘額
export const getBalance = async () => {
    const response = await apiRequest('/account/GetBalance', 'GET', null, true); // `true` 表示此請求需要身份驗證
    return await response.json();

};

// 其他需要身份驗證的 API，如存款、提款等
export const deposit = async (currencyId, amount, remark) => {
    return await apiRequest('/transaction/deposit', 'POST', {currencyId, amount, remark}, true);

};

export const withdraw = async (currencyId, amount, type, bankId, accountInfo, remark = '') => {
    return await apiRequest('/transaction/withdraw', 'POST', {
        currencyId,
        amount,
        type,
        bankId,
        accountInfo,
        remark
    }, true);
};

export const transfer = async (currencyId, amount, accountInfo, remark = '') => {
    return await apiRequest('/transaction/transfer', 'POST', {
        currencyId,
        amount,
        accountInfo,
        remark
    }, true);

};


export const exchange = async (fromCurrencyId, toCurrencyId, amount) => {
    return await apiRequest('/transaction/exchange', 'POST', {fromCurrencyId, toCurrencyId, amount}, true);
};

export const getTransactions = async (filterPayload) => {
    const response = await apiRequest('/transaction/GetTransactions', 'POST', filterPayload, true); // `true` 表示此請求需要身份驗證
    return await response.json();
};
