import {useNavigate} from "react-router-dom";
import LandingLayout from "../components/LandingLayout";
import smallLogo from "../assets/logo-orange.png";
import React, {useEffect, useState} from "react";
import InputField from "../components/InputField";
import {register} from "../services/apiService";
import {useTranslation} from "react-i18next";
import {useLocation} from 'react-router-dom';
import {useAuth} from "../services/authContext";

export default function RegisterPage() {
    const {t} = useTranslation();
    const {setName} = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [error, setError] = useState('');
    const [clickDisabled, setClickDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload on form submit

        // Input validation
        if (!username || !password || !email || !password2) {
            setError(t('fillAllFields'));
            return;
        }

        if (password !== password2) {
            setError(t('psDifferent'));
            return;
        }

        setError('');
        setLoading(true);

        try {
            const data = await register(username, email, password, referralCode);
            console.log("data", data);
            if(data.status === 400){
                setError(t(data.title));
            }
            else if (data.ok) {
                console.log("register Successful", data);
                setName(username);
                navigate("/mobileVerify");
            } else {
                console.log("data description", data[0].description);
                setError(data[0].description)
            }

        } catch (error) {
            console.error('Error during login:', error);
            setError(t('registerFailed'));
        } finally {
            setLoading(false);

        }
    };
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('referral');
        if (code) {
            setReferralCode(code);
        }
    }, [location]);

    const inputFields = [
        {
            label: t('userName'),
            placeholder: t('userNamePlaceholder'),
            value: username,
            onChange: (e) => setUsername(e.target.value)
        },
        {
            label: t('email'),
            placeholder: t('emailPlaceholder'),
            value: email,
            onChange: (e) => setEmail(e.target.value),
            note: "*請確保信箱可收取驗證信"
        },
        {
            label: t('friendReferral'),
            placeholder: t('friendReferralPlaceholder'),
            value: referralCode,
            onChange: (e) => setReferralCode(e.target.value)
        },
        {
            label: t('pw'),
            placeholder: t('psPlaceholder'),
            type: "password",
            value: password,
            onChange: (e) => setPassword(e.target.value)
        },
        {
            label: t('rePw'),
            placeholder: t('rePsPlaceholder'),
            type: "password",
            value: password2,
            onChange: (e) => setPassword2(e.target.value),
            note: "*請確保密碼正確無誤"
        },
    ];

    useEffect(() => {
        if (username && password && email && password2) {
            setClickDisabled(false);
        } else {
            setClickDisabled(true);
        }
    }, [username, password, email, password2]);

    return (
        <LandingLayout>

            <div
                className="inline-flex flex-col  items-center justify-center gap-2 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">

                <img className="relative w-[78px] h-[59px]" alt="Group" src={smallLogo}/>
                <div
                    className="relative w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center tracking-[0] leading-[normal]">
                    {t('register')}
                </div>


                {inputFields.map((field, index) => (
                    <InputField key={index} {...field} />
                ))}

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>
                    </div>
                )}

                <div
                    onClick={!loading && !clickDisabled ? handleSubmit : null}  // Disable click when loading
                    className={`flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] ${
                        loading && !clickDisabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    <div
                        className="w-[330px] mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]"
                    >
                        {loading ? t('processing') : t('register')}
                    </div>
                </div>
                <div className="flex w-[330px] items-center justify-end gap-2.5 relative flex-[0_0_auto]">
                    <p className="w-[300px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        <span className="text-[#61554e]">{t('loginReminderMsg')}&nbsp;&nbsp;</span>
                        <span className="text-[#ff9b63] underline" onClick={() => {
                            navigate('/login')
                        }}>{t('loginLink')}</span>
                    </p>
                </div>
            </div>

        </LandingLayout>
    );
}
