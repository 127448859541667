import PropTypes from "prop-types";
import React, { useReducer } from "react";
import {DropdownWrapper} from "./DropdownWrapper";
import rectangle from "../assets/Rectangle 7.svg"
export const CurrencyDropdown = ({ property1, className, currencyCodes, onClick, currentCode }) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });

    return (
        <div
            className={`w-[140px] flex items-center gap-2.5 rounded-[5px] relative ${
                state.property1 === "variant-2" ? "flex-col" : ""
            } ${state.property1 === "variant-2" ? "p-2.5" : "px-2.5 py-2"} ${
                state.property1 === "variant-2" ? "" : "h-[47px]"
            } ${state.property1 === "default" ? "justify-around" : ""} ${
                state.property1 === "variant-2"
                    ? "bg-variable-collection-01"
                    : "bg-[#fff7e4b2]"
            } ${className}`}
            onClick={() => {
                dispatch("click");
            }}
        >
            {state.property1 === "default" && (
                <div className="relative w-[122px] h-7 mr-[-2.00px]">
                    <div className="flex items-center w-[51px] h-7 top-0 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[14px] tracking-[0] leading-[normal]">
                        {currentCode}
                    </div>
                    <img
                        className="absolute w-[7px] h-1 top-3.5 left-[113px]"
                        alt="Rectangle"
                        src={rectangle}
                    />
                </div>
            )}

            {state.property1 === "variant-2" && (
                <>
                    <div className="flex w-[120px] items-center justify-between relative flex-[0_0_auto]">
                        <div
                            className="relative w-[118px] h-7 mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[14px] tracking-[0] leading-[normal]">
                            請選擇幣別
                        </div>
                        <img
                            className="relative w-[7.49px] h-[4.45px] mr-[-0.35px] ml-[-5.14px]"
                            alt="Rectangle"
                            src={rectangle}
                        />
                    </div>
                    <svg width="91" height="2" viewBox="0 0 91 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 1H90.5" stroke="#62554E" strokeWidth="0.5"/>
                    </svg>
                    {
                        currencyCodes && currencyCodes.map((code, index) => (
                            <DropdownWrapper
                                key={index}
                                className="!mr-[-10.00px] !ml-[-10.00px] !flex-[0_0_auto]"
                                property1="default"
                                rectangle={rectangle}
                                code={code}
                                onclick ={()=> onClick(code)}
                            />
                        ))
                    }
                </>
            )}
        </div>
    );
};

function reducer(state, action) {
    if (state.property1 === "default") {
        switch (action) {
            case "click":
                return {
                    property1: "variant-2",
                };
        }
    }

    if (state.property1 === "variant-2") {
        switch (action) {
            case "click":
                return {
                    property1: "default",
                };
        }
    }

    return state;
}

CurrencyDropdown.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
};
