import smallLogo from "../assets/logo-orange.png";
import React, { useState} from "react";
import MainLayout from "../components/MainLayout";
import {changePassword, deposit, getCurrencies, getWallets, verifyEmail} from "../services/apiService";
import {usePopup} from "../services/PopupContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import PopupConfirm from "../components/PopupConfirm";

export default function ChangePasswordPage() {

    const {isOpen, showPopup, hidePopup} = usePopup();
    const [loading, setLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const {t, i18n} = useTranslation();
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        setError(''); // Clear the error if inputs are valid

        if (newPassword !== confirmNewPassword) {
            setError(t('psDifferent'));
        } else if (currentPassword && newPassword && confirmNewPassword) {
            setLoading(true);

            const resp = await changePassword(currentPassword, newPassword);

            if (resp.status === 400) {
                setError(t(resp.title));
            } else if (resp.ok) {
                showPopup();
            }
            else {
                setError(t('systemError'));
            }
            setLoading(false);

        } else {
            setError(t('fillAllFields'));
        }

    };

    const goBack = () => {
        navigate(-1); // -1 表示返回上一頁
    };
    return (

        <MainLayout>
            <div
                className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="w-[78px] h-[59px]"/>
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                    {t('changePasswordTitle')}
                </div>


                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('currentPassword')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('enterCurrentPassword')}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('newPassword')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('enterNewPassword')}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('reEnterNewPassword')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('confirmReEnterNewPassword')}
                        />
                    </div>
                </div>
                {error &&
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>

                    </div>}
                <div onClick={!loading ? handleSubmit : null}
                     className={`flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] ${
                         loading ? 'opacity-50 cursor-not-allowed' : ''
                     }`}>
                    <div
                        className="relative w-[350px] mt-[-2.00px] ml-[-24.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center tracking-[0] leading-[50px]">
                        {loading ? t('processing') : t('confirmButton')}
                    </div>
                </div>
                <div className="flex w-[330px] items-center justify-end gap-2.5 relative flex-[0_0_auto]">
                    <p className="w-[300px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        <span className="text-[#61554e]">
                            {t('passwordTip')}
                        </span>
                    </p>
                </div>
            </div>
            <PopupConfirm onConfirm={goBack} onClose={goBack} isOpen={isOpen} title={t('passwordChangeSuccess')}
                          msg={t('passwordChangeMsg')}></PopupConfirm>
        </MainLayout>
    );
}
