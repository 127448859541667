import React from "react";

function InputField({ label, placeholder, type = "text", value,onChange, note, onKeyDown=null }) {
    return (
        <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
            <div
                className="w-[330px] h-[33px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                {label}
            </div>
            <div
                className="flex w-[330px] items-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">
                <input
                    type={type}
                    id={label}
                    placeholder={placeholder}
                    className="border-none focus:outline-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px]  leading-[50px] whitespace-nowrap relative tracking-[0]"
                    aria-label={label}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
            </div>
            {
                note && <div
                    className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                    {note}
                </div>
            }

        </div>
    );
}

export default InputField;
