import smallLogo from "../assets/logo-orange.png";
import React from "react";
import closeIcon from "../assets/close.svg"
import {useTranslation} from "react-i18next";

export default function PopupConfirm({isOpen, onClose, title,msg, onConfirm}) {
    if (!isOpen) return null;
    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="bg-white rounded-2xl p-6 shadow-lg w-[350px] md:w-[450px] relative">
                    <img className="absolute top-4 right-4 w-[20px] h-[20px] cursor-pointer" src={closeIcon} alt="Close"
                         onClick={onClose} />
                    <div className="flex justify-center w-full mb-[20px]">
                        <img src={smallLogo}
                             className="w-[78px] h-[59px]"/>
                    </div>
                    <div
                        className=" [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0] mb-[20px]">
                        {title}
                    </div>
                    <div
                        className="[font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] leading-5 relative  text-center tracking-[0] mb-[20px]"
                    >
                        {msg}
                    </div>
                    <div onClick={onConfirm}
                         className="flex items-center justify-center gap-2.5 px-3.5 py-0 rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] relative  flex-[0_0_auto]"
                    >
                        <div
                            className="mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] leading-[50px] relative  text-center tracking-[0]"
                        >
                            {t('confirmButton')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
