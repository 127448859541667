// components/Layout.js
export default function DashboardLayout({ children }) {
    return (
        <div
            className="bg-[#ffe7b7]">


            {/* Main Content */}
            <main className="">
                {children} {/* This will render the page-specific content */}
            </main>

        </div>
    );
}
