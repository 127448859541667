import smallLogo from "../assets/logo-orange.png";
import React, {useEffect, useState} from "react";
import MainLayout from "../components/MainLayout";
import {deposit, exchange, getExchangeRates, getWallets} from "../services/apiService";
import {useNavigate} from "react-router-dom";
import {usePopup} from "../services/PopupContext";
import TransactionProcessingPopup from "../components/TransactionProcessingPopup";
import {useTranslation} from "react-i18next";
import Wallets from "../components/wallets";

export default function ExchangePage() {
    const [amount, setAmount] = useState('');
    const [wallets, setWallets] = useState([]);
    const [currencyId, setCurrencyId] = useState('');
    const [targetCurrencyId, settTargetCurrencyId] = useState('');
    const [exchangeData, setExchangeData] = useState([]);
    const [rate, setRate] = useState('');
    const [exchangeAmount, setExchangeAmount] = useState('');
    const {isOpen, showPopup, hidePopup} = usePopup();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const wallets = await getWallets();

                if (wallets.length > 0) {
                    setWallets(wallets);
                    setCurrencyId(wallets[0].currencyId);
                }
            } catch (error) {
                console.error('Error  getWallets:', error);
            }
        };
        const fetchExchangeRates = async () => {
            try {
                const response = await getExchangeRates();
                setExchangeData(response);
            } catch (error) {
                console.error("Error fetching exchange rates:", error);
            }
        };

        fetchExchangeRates();
        fetchBalance();

    }, [])

    useEffect(() => {
        if (currencyId && targetCurrencyId) {
            console.log("currencyId ", currencyId);
            console.log("exchangeData ", exchangeData);
            const data = exchangeData.find(x => x.currency.id == currencyId);
            console.log("data", data)
            if (data) {
                const exchangeRate = data.exchangeRates.find(x => x.toCurrencyId == targetCurrencyId);
                console.log("exchangeRate", exchangeRate)
                if (exchangeRate) {
                    setRate(exchangeRate.rate);
                    const calculatedAmount = amount * exchangeRate.rate;
                    const roundedUpAmount = Math.ceil(calculatedAmount * 100) / 100; // Round up to 2 decimal places
                    setExchangeAmount(roundedUpAmount);
                }
            }
        }

    }, [targetCurrencyId, currencyId]);

    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        const wallet = wallets.find(x => x.currencyId == currencyId);
        console.log("wallet", wallet);
        if(wallet.balance < amount) {
            setError(t('insufficientBalance'));
        }
        else if (currencyId && targetCurrencyId && amount && amount > 0) {
            setError('');
            setLoading(true);

            const resp = await exchange(currencyId, targetCurrencyId, amount);
            if (resp.status === 400) {
                setError(t(resp.title));
            } else if (resp.ok) {
                setError('');
                showPopup();
            }
            else {
                setError(t('systemError'));
            }
            setLoading(false);

        }
        else {
            setError(t('fillAllFields'));
        }

    };

    return (
        <MainLayout>
            <div
                className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="w-[78px] h-[59px]"/>
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                    {t('exchange')}
                </div>
                <Wallets wallets={wallets}></Wallets>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#7f7f7f] text-[15px] leading-[35px] whitespace-nowrap relative tracking-[0]">
                        {t('exchangeAmount')}
                    </div>
                    <div
                        className="flex w-[330px] items-center justify-between px-3.5 py-2 relative flex-[0_0_auto] rounded-[5px] border border-solid border-[#e8e8e8]">
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="border-none focus:outline-none focus:border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-[#62554e] text-sm leading-[normal] placeholder-[#b2b2b2]"
                            placeholder={t('exchangeAmountPlaceholder')}
                        />
                        <div className="flex items-center justify-center relative w-[78px]">
                            <div className="relative w-[15px] h-[15px] bg-variable-collection-03 rounded-[7.5px]"/>

                            <select
                                className="w-[60px] border-none focus:outline-none [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-sm text-center"
                                value={currencyId} onChange={(e) => {
                                setCurrencyId(e.target.value)
                            }}>
                                <option value="" disabled></option>
                                {
                                    wallets.map((data, index) => (
                                        <option key={index} value={data.currencyId}>{data.currencyName}</option>
                                    ))
                                }
                            </select>

                        </div>
                    </div>
                    <div
                        className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#7f7f7f] text-[15px] text-right leading-5 relative tracking-[0]">
                        {t('exchangeMsg')}
                    </div>
                </div>

                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('toCurrency')}
                    </div>
                    <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                        <div
                            className="flex w-28 items-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">
                            <div className="flex w-[88px] h-[50px] items-center justify-center gap-2.5 relative">
                                <div className="relative w-[15px] h-[15px] bg-variable-collection-03 rounded-[7.5px]"/>

                                <select
                                    className="w-[60px] border-none focus:outline-none [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-sm text-center"
                                    value={targetCurrencyId} onChange={(e) => {
                                    settTargetCurrencyId(e.target.value)
                                }}>
                                    <option value="" disabled></option>
                                    {exchangeData.map((data, index) => (
                                        <option key={index} value={data.currency.id}>
                                            {data.currency.code}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="inline-flex flex-col items-end justify-center relative flex-[0_0_auto]">
                            <div
                                className="w-[86px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                                {t('avExchangeAmount')}
                            </div>
                            <div
                                className="w-[143px] h-[30px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-right leading-[normal] relative tracking-[0]">
                                {exchangeAmount}
                            </div>
                        </div>
                    </div>
                    <div
                        className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        {t('currentExchangeRate')} {rate}
                    </div>
                </div>
                {error &&
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>

                    </div>}
                <div className="flex w-[330px] h-[50px] items-center justify-between relative">
                    <div onClick={!loading ? handleSubmit : null}
                         className={`flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] ${
                             loading ? 'opacity-50 cursor-not-allowed' : ''
                         }`}>
                        <div
                            className="w-40 mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]">
                            {loading ? t('processing') : t('exchangeConfirm') }
                        </div>
                    </div>
                    <div onClick={() => {
                        navigate("/deposit")
                    }}
                         className="flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-variable-collection-04">
                        <div
                            className="w-40 mt-[-1.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-04 text-[15px] text-center leading-[50px] relative tracking-[0]">
                            {t('deposit')}
                        </div>
                    </div>
                </div>
                <div className="flex w-[330px] items-center justify-end gap-2.5 relative flex-[0_0_auto]">
                    <p className="w-[300px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        <span className="text-[#61554e]">{t('csReminder')}&nbsp;&nbsp;</span>
                        <span className="text-[#ff9b63] underline" onClick={()=>{navigate('/ContactUs')}}> {t('csLink')}</span>
                    </p>
                </div>


            </div>
            <TransactionProcessingPopup isOpen={isOpen} onClose={hidePopup}></TransactionProcessingPopup>

        </MainLayout>
    );
}
