import smallLogo from "../assets/logo-orange.png";
import React, {useEffect, useState} from "react";
import rectangle from "../assets/Rectangle 7.svg"
import {sendOtp, verifyPhone} from "../services/apiService";
import {useAuth} from "../services/authContext";
import {useNavigate} from "react-router-dom";
import LandingLayout from "../components/LandingLayout";
import {useTranslation} from "react-i18next";

export default function MobileVerifyPage() {
    const {userName} = useAuth();
    const {t, i18n} = useTranslation();

    const [areaCode, setAreaCode] = useState('+886');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [otpLoading, setOtpLoading] = useState(false);
    const [countDown, setCountDown] = useState(60);
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        setError(''); // Clear the error if inputs are valid

        const resp = await verifyPhone(userName, code);
        if (resp.status === 400) {
            setError(t(resp.title));
        } else if (resp.ok) {
            navigate('/verifyEmail');
        }
        else {
            setError(t('systemError'));
        }
    };
    const handleSendOtp = async (e) => {
        e.preventDefault();
        setOtpLoading(true);

       const resp= await sendOtp(userName, areaCode, phone);
        if (resp.status === 400) {
            setError(t(resp.title));
        } else if (resp.ok) {
            setError('');
        }
        else {
            setError(t('systemError'));
        }

    }
    useEffect(() => {
        let timer;
        if (otpLoading && countDown > 0) {
            timer = setTimeout(() => {
                setCountDown(countDown - 1);
            }, 1000); // 每一秒減少一次
        } else if (countDown === 0) {
            setOtpLoading(false);
        }

        return () => clearTimeout(timer); // 清除計時器
    }, [countDown, otpLoading]);
    return (

        <LandingLayout>
            <div
                className="inline-flex flex-col h-[626px] items-center gap-5 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="w-[78px] h-[59px]"/>
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                    {t('phoneTitle')}
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="relative w-[330px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                        {t('phoneNumber')}
                    </div>
                    <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                        <div
                            className="flex w-[63px] items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                            <select
                                className="border-none w-[320px] h-full appearance-none outline-none bg-transparent cursor-pointer text-variable-collection-05"
                                style={{
                                    fontFamily: 'Inter-Regular, Helvetica',
                                    fontSize: '15px',
                                    textAlign: 'center',
                                    lineHeight: '50px'
                                }}
                                defaultValue="" value={areaCode} onChange={(e) => {
                                setAreaCode(e.target.value)
                            }}>
                                <option value="+886">+886</option>
                                <option value="+61">+61</option>
                                <option value="+81">+81</option>

                            </select>
                            <img
                                className="relative w-[6.86px] h-[12.31px]"
                                alt="Rectangle"
                                src={rectangle}
                            />
                        </div>
                        <div
                            className="flex w-[251px] items-center gap-2.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                                placeholder= {t('phonePlaceholder')}
                            />
                        </div>
                    </div>
                    <div
                        className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        {t('sendOtpInfo')}

                    </div>
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="relative w-[330px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                        {t('otpCode')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder= {t('phoneOtpPlaceholder')}
                        />
                        <div onClick={otpLoading ? null : handleSendOtp}
                             className={`flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-variable-collection-04  ${
                                 otpLoading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                            <div
                                className="w-40 mt-[-1.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-04 text-[15px] text-center leading-[50px] relative tracking-[0]">
                                {t('sendOtp')}
                            </div>
                        </div>
                    </div>

                    <p className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        <span className="text-[#61554e]"> {t('noPhoneOtpReceived')}</span>
                    </p>
                    <div
                        className="w-[330px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        {t('otpWarning')}
                    </div>
                </div>
                <div className="relative w-[330px] h-[86px] bg-white"/>
                {error &&
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>

                    </div>}
                <div onClick={handleSubmit}
                     className="flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]">
                    <div
                        className="w-[330px] mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]">
                        {t('confirmButton')}
                    </div>
                </div>
            </div>
        </LandingLayout>
    );
}
