import deposit from "../assets/mdi_hand-coin.svg"
import transfer from "../assets/solar_round-transfer-horizontal-bold.svg"
import exchange from "../assets/change.png"
import withdraw from "../assets/money-withdrawal.png"
import {useNavigate} from "react-router-dom";
import {useAuth} from "../services/authContext";
import {useTranslation} from "react-i18next";

export default function QuickAction() {
    const { isLoggedIn } = useAuth();
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    function getOnClick(url) {

        return () => navigate( isLoggedIn?url:"/login");
    }

    return (
        <div className="flex w-[350px] items-center justify-evenly relative flex-[0_0_auto]">
            <div className="flex flex-col w-[65px] items-center gap-2 relative" onClick={getOnClick("/deposit")}>
                <div
                    className="flex flex-col w-[60px] h-[60px] items-center justify-center gap-2.5 px-2.5 py-[18px] relative bg-variable-collection-04 rounded-[10px] border-2 border-solid border-[#fff7f3] [background:linear-gradient(180deg,rgb(255,155.72,99.88)_0%,rgb(214.62,89.86,19.67)_100%)]">
                    <img className="relative w-[30px] h-[30px] mt-[-3.00px] mb-[-3.00px]" src={deposit}/></div>
                <div
                    className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-01 text-[15px] text-center tracking-[0] leading-[normal]"> {t('deposit')}
                </div>
            </div>

            <div className="flex flex-col w-[65px] items-center gap-2 relative" onClick={getOnClick("/exchange")}>
                <div
                    className="flex flex-col w-[60px] h-[60px] items-center justify-center gap-2.5 px-2.5 py-[18px] relative bg-variable-collection-04 rounded-[10px] border-2 border-solid border-[#fff7f3] [background:linear-gradient(180deg,rgb(255,155.72,99.88)_0%,rgb(214.62,89.86,19.67)_100%)]">
                    <img className="relative w-[30px] h-[30px] mt-[-3.00px] mb-[-3.00px]" src={exchange}/></div>
                <div
                    className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-01 text-[15px]s text-center tracking-[0] leading-[normal]">{t('exchange')}
                </div>
            </div>

            <div className="flex flex-col w-[65px] items-center gap-2 relative" onClick={getOnClick("/withdraw")}>
                <div
                    className="flex flex-col w-[60px] h-[60px] items-center justify-center gap-2.5 px-2.5 py-[18px] relative bg-variable-collection-04 rounded-[10px] border-2 border-solid border-[#fff7f3] [background:linear-gradient(180deg,rgb(255,155.72,99.88)_0%,rgb(214.62,89.86,19.67)_100%)]">
                    <img className="relative w-[30px] h-[30px] mt-[-3.00px] mb-[-3.00px]" src={withdraw}/></div>
                <div
                    className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-01 text-[15px] text-center tracking-[0] leading-[normal]">{t('withdraw')}
                </div>
            </div>

            <div className="flex flex-col w-[65px] items-center gap-2 relative" onClick={getOnClick("/transfer")}>
                <div
                    className="flex flex-col w-[60px] h-[60px] items-center justify-center gap-2.5 px-2.5 py-[18px] relative bg-variable-collection-04 rounded-[10px] border-2 border-solid border-[#fff7f3] [background:linear-gradient(180deg,rgb(255,155.72,99.88)_0%,rgb(214.62,89.86,19.67)_100%)]">
                    <img className="relative w-[45px] h-[45px] mt-[-3.00px] mb-[-3.00px]" src={transfer}/></div>
                <div
                    className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-01 text-[15px] text-center tracking-[0] leading-[normal]">轉帳
                </div>
            </div>
        </div>
    );
}
