import PropTypes from "prop-types";
import React, { useReducer } from "react";

export const TargetCurrencyRate = ({ property1, className, code, rate }) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });

    return (
        <div
            className={`w-[350px] flex items-center px-2.5 py-[5px] h-10 justify-between relative ${
                state.property1 === "variant-2" ? "bg-variable-collection-02" : ""
            } ${className}`}
            onMouseLeave={() => dispatch("mouse_leave")}
            onMouseEnter={() => dispatch("mouse_enter")}
        >
            <div className="w-[174.5px] flex mt-[-10.00px] items-center gap-2.5 mb-[-10.00px] relative">
                <div
                    className={`border border-solid border-white w-[19px] h-[19px] rounded-[9.5px] relative ${
                        state.property1 === "default"
                            ? "[background:linear-gradient(180deg,rgb(255,231,183)_0%,rgb(255,153,0)_100%)]"
                            : ""
                    } ${
                        state.property1 === "variant-2"
                            ? "bg-variable-collection-03"
                            : "bg-variable-collection-02"
                    }`}
                />
                <div className="[font-family:'Inter-Regular',Helvetica] w-fit mt-[-1.00px] tracking-[0] text-[15px] text-variable-collection-05 font-normal text-center whitespace-nowrap leading-[50px] relative">
                    {code}
                </div>
            </div>
            <div className="[font-family:'Inter-Regular',Helvetica] w-fit mt-[-6.00px] tracking-[0] text-[15px] text-variable-collection-05 font-normal text-center whitespace-nowrap mb-[-4.00px] leading-10 relative">
                {rate}
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "variant-2",
            };
        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };
        default:
            return state;
    }
}

TargetCurrencyRate.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
};
