import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import LandingLayout from "../components/LandingLayout";
import logo from "../assets/HHlogo.png";
import logo_cn from "../assets/HHlogo-cn.png";

export const LandingPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Set a timer to navigate to the login page after 2 seconds
        const timer = setTimeout(() => {
            navigate("/dashboard");
        }, 2000);

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, [navigate]);



    return (
        <LandingLayout>
            <div className="flex flex-col items-center justify-center h-screen">
                <img className="relative w-[190px] h-[149px]" alt="Momo" src={logo}/>
                <div className="relative  h-[20px]"/>

                <div
                    className="absolute bottom-12 w-fit [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-01 text-[15px] tracking-[0] leading-[normal]">
                    <img className="w-[106px] h-[50px]" src={logo_cn}/>

                    <div>全球換匯平台</div>
                </div>
            </div>

        </LandingLayout>
    );
};
