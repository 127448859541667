import "../global.css";
import React from "react";
import ReactDOMClient from "react-dom/client";
import {LandingPage} from "./screens/LandingPage";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import LoginPage from "./screens/LoginPage";
import RegisterPage from "./screens/RegisterPage";
import EmailVerificationPage from "./screens/EmailVerificationPage";
import VerificationSuccessPage from "./screens/VerificationSuccessPage";
import Dashboard from "./screens/Dashboard";
import DepositPage from "./screens/DepositPage";
import ExchangePage from "./screens/ExchangePage";
import WithdrawPage from "./screens/WithdrawPage";
import {AuthProvider} from "./services/authContext";
import {PopupProvider} from "./services/PopupContext";
import BalancePage from "./screens/BalancePage";
import ProfilePage from "./screens/ProfilePage";
import MobileVerifyPage from "./screens/MobileVerifyPage";
import TransactionsPage from "./screens/TransactionsPage";
import './i18n'
import ContactUsPage from "./screens/ContactUsPage";
import TransferPage from "./screens/TransferPage";
import ChangePasswordPage from "./screens/ChangePasswordPage";

const App = () => {
    return (
        <AuthProvider>
                <BrowserRouter>
                    <PopupProvider>
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/signup" element={<RegisterPage/>}/>
                        <Route path="/verifyEmail" element={<EmailVerificationPage/>}/>
                        <Route path="/verifySuccess" element={<VerificationSuccessPage/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/deposit" element={<DepositPage/>}/>
                        <Route path="/exchange" element={<ExchangePage/>}/>
                        <Route path="/withdraw" element={<WithdrawPage/>}/>
                        <Route path="/transfer" element={<TransferPage/>}/>
                        <Route path="/balance" element={<BalancePage/>}/>
                        <Route path="/profile" element={<ProfilePage/>}/>
                        <Route path="/mobileVerify" element={<MobileVerifyPage/>}/>
                        <Route path="/transactions" element={<TransactionsPage/>}/>
                        <Route path="/ContactUs" element={<ContactUsPage/>}/>
                        <Route path="/ChangePassword" element={<ChangePasswordPage/>}/>
                    </Routes>
                    </PopupProvider>
                </BrowserRouter>
        </AuthProvider>
    );
};

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<App/>);
