import smallLogo from "../assets/logo-orange.png";
import React, {useEffect, useState} from "react";
import MainLayout from "../components/MainLayout";
import {getBalance, getWallets} from "../services/apiService";
import icon from "../assets/Ellipse 4.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function BalancePage() {
    const [wallets, setWallets] = useState([]);
    const [balance, setBalance] = useState({
        totalBalance:'',
        totalPendingBalance:'',
    });
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const fetchWallets = async () => {
            try {
                const wallets = await getWallets();

                if (wallets.length > 0) {
                    setWallets(wallets);
                }
            } catch (error) {
                console.error('Error fetching wallets:', error);
            }
        };
        const fetchBalance = async () => {
            try {
                const balance = await getBalance();

                if (balance) {
                    setBalance(balance);
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };

        fetchWallets();
        fetchBalance();
    }, []);

    return (

        <MainLayout>
            <div className="inline-flex flex-col items-center px-5 py-3 relative">
                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02 mb-[20px]">
                    <div className="flex justify-center w-full">
                        <img src={smallLogo}
                             className="w-[78px] h-[59px]"/>
                    </div>
                    <div
                        className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                        {t('myAssetsTitle')}
                    </div>
                    <div className="flex flex-col w-[330px] items-start gap-2.5 relative flex-[0_0_auto]">
                        <div
                            className="w-[330px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] relative tracking-[0] leading-[normal]">
                            {t('accountBalance')}
                        </div>
                        <div className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
                            <div
                                className="w-[350px] mt-[-1.00px] mr-[-20.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-[#ff9b63] text-2xl relative tracking-[0] leading-[normal]">
                                {balance.totalBalance}
                            </div>
                            <div
                                className="relative w-[50px] ml-[-70px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[normal]">
                                USDT
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[330px] items-start gap-2.5 relative flex-[0_0_auto]">
                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="w-[125px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] relative tracking-[0] leading-[normal]">
                                {t('availableBalance')}
                            </div>
                            <div
                                className="w-[100px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] text-right relative tracking-[0] leading-[normal]">
                                {t('frozenAssets')}
                            </div>
                        </div>
                        <div className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
                            <div
                                className="w-[350px] mt-[-1.00px] mr-[-20.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-[15px] relative tracking-[0] leading-[normal]">
                                {balance.totalBalance}
                            </div>
                            <div
                                className="relative w-[150px] ml-[-70px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[normal]">
                                {balance.totalPendingBalance} USDT
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                    <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                        <div
                            className="relative w-[125px] h-[23px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-[15px] tracking-[0] leading-[normal]">
                            {t('accountFunds')}
                        </div>
                        <div onClick={()=>{navigate("/transactions")}}
                            className="flex w-[111px] h-10 items-center justify-center gap-2.5 relative rounded-[3px] border-[0.5px] border-solid border-variable-collection-04">
                            <div
                                className="w-[65px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-04 text-[15px] text-center leading-[normal] relative tracking-[0]">
                                {t('transactionHistory')}
                            </div>
                        </div>
                    </div>
                    {
                        wallets.map((data, index) => (
                            <div key={index}>
                                <img className="relative w-[330px] h-px" alt="Vector" src={icon}/>
                                <div
                                     className="inline-flex flex-col gap-2.5 flex-[0_0_auto] items-center relative">
                                    <div
                                        className="flex flex-col w-[330px] items-start gap-2.5 relative flex-[0_0_auto]">
                                        <div
                                            className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                                            <div className="flex w-[172.5px] gap-2.5 items-center relative">
                                                <div
                                                    className="relative w-[15px] h-[15px] bg-variable-collection-03 rounded-[7.5px]"/>
                                                <div
                                                    className="relative w-[100px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] tracking-[0] leading-[normal]">
                                                    {data.currencyName}
                                                </div>
                                            </div>
                                            <p className="relative w-[100px] mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[normal]">
                                                <span className="font-bold">{data.balance} </span>
                                                <span
                                                    className="[font-family:'Inter-Regular',Helvetica] text-[15px]">{data.currencyName}</span>
                                            </p>
                                        </div>
                                        <div
                                            className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
                                            <div
                                                className="w-[150px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] leading-[normal] relative tracking-[0]">
                                                {t('frozenAmount')}
                                            </div>
                                            <div
                                                className="relative w-[150px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[normal]">
                                                {data.pendingBalance} {data.currencyName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <div className="relative w-[330px] h-9 bg-white"/>
                    <div className="flex w-[330px] h-[50px] justify-between mb-[-4.00px] items-center relative">
                        <div onClick={() => {
                            navigate("/exchange")
                        }}
                             className="flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]">
                            <div
                                className="w-40 mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]">
                                {t('exchange')}
                            </div>
                        </div>
                        <div onClick={() => {
                            navigate("/withdraw")
                        }}
                             className="flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-variable-collection-04">
                            <div
                                className="w-40 mt-[-1.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-04 text-[15px] text-center leading-[50px] relative tracking-[0]">
                                {t('withdraw')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}
