import {useEffect, useState} from "react";
import {getProfile, getWallets} from "../services/apiService";
import {useAuth} from "../services/authContext";
import icon from "../assets/clarity_avatar-solid.svg"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Balance(props) {
    const {isLoggedIn} = useAuth();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [wallet, setWallet] = useState({balance: 0, currencyCode: ''});
    const [profile, setProfile] = useState({name: ''});
    useEffect(() => {
        if (isLoggedIn) {
            const fetchWallets = async () => {
                try {
                    const wallets = await getWallets();

                    if(wallets.length > 0) {
                        setWallet(wallets[0]);
                    }
                } catch (error) {
                    console.error('Error fetching balance:', error);
                }
            };

            fetchWallets();
        }
    }, []);


    useEffect(() => {
        if (isLoggedIn) {
            const fetchProfile = async () => {
                try {
                    const profile = await getProfile();
                    setProfile(profile);
                } catch (error) {
                    console.error('Error fetching profile:', error);
                }
            };

            fetchProfile();
        }
    }, []);


    return (
        <>
            <div className="inline-flex flex-col items-start gap-2.5 relative flex-[0_0_auto]">
                {
                    isLoggedIn && <div
                        className="relative w-[350px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] tracking-[0] leading-[normal]">{t('welcomeMsg')}
                    </div>
                }
                <div className="flex w-[350px] items-center justify-between relative flex-[0_0_auto]">
                    <div
                        className="w-[125px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-2xl relative tracking-[0] leading-[normal]"> {profile.name}
                    </div>
                    <div onClick={()=>{navigate(isLoggedIn?'/profile':'/login')}}
                        className="flex w-[31px] h-[31px] items-center justify-center gap-2.5 p-2 relative bg-white rounded-[15.5px] border-2 border-solid border-white [background:linear-gradient(180deg,rgb(255,155.72,99.88)_0%,rgb(214.62,89.86,19.67)_100%)]">
                        <img className="relative w-[15px] h-[15px] " src={icon}/>

                    </div>
                </div>
            </div>
            <div className="inline-flex flex-col items-start gap-2.5 relative flex-[0_0_auto]">

                    <div
                        className="relative w-[350px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#62554e] text-[15px] tracking-[0] leading-[normal]">
                        {t('accountBalance')}
                    </div>
                    <div className="flex w-[350px] items-center justify-between relative flex-[0_0_auto]">
                        <div className="inline-flex items-center gap-2.5 relative flex-[0_0_auto]">
                            <div
                                    className="w-[140px] mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-01 text-2xl relative tracking-[0] leading-[normal]">
                                    {isLoggedIn?wallet.balance:"****"}
                                </div>
                                <div
                                    className="relative w-7 [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[normal]">
                                    {isLoggedIn ? wallet.currencyName:"****"}
                                </div>

                        </div>
                        <div onClick={() => {
                            navigate(isLoggedIn?"/balance":"/login")
                        }}
                             className="flex w-[111px] h-[63px] items-center justify-center gap-2.5 relative bg-[#ffe7b780] rounded-[3px] border border-solid border-variable-collection-01">
                            <div
                                className="w-[65px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-center relative tracking-[0] leading-[normal]">
                                {t('moreBalance')}
                            </div>
                        </div>
                    </div>


            </div>
        </>);
}
