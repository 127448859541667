import React, {useEffect, useState} from "react";
import MainLayout from "../components/MainLayout";
import {getProfile} from "../services/apiService";
import {useNavigate} from "react-router-dom";
import rectangle from "../assets/Rectangle 7.svg"
import {useAuth} from "../services/authContext";
import PopupConfirm from "../components/PopupConfirm";
import {usePopup} from "../services/PopupContext";
import {useTranslation} from "react-i18next";

export default function ProfilePage() {
    const navigate = useNavigate();
    const [profile, setProfile] = useState({name: ''});
    const {logout} = useAuth();
    const { isOpen, showPopup, hidePopup } = usePopup();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profile = await getProfile();
                setProfile(profile);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, []);

    const handleLogout=()=>{
        logout();
        hidePopup();
        navigate("/dashboard")
    }

    return (

        <MainLayout>
            <div className="inline-flex flex-col items-center">

                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02 mb-[20px]">
                    <div
                        className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                        {t('personalInfoTitle')}
                    </div>
                    <div className="inline-flex flex-col items-start gap-2.5 relative flex-[0_0_auto]">

                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                                {t('account')}
                            </div>
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[50px] whitespace-nowrap">
                                {profile.name}
                            </div>
                        </div>
                        <svg width="330" height="2" viewBox="0 0 330 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1H330" stroke="#E8E8E8" strokeWidth="0.5"/>
                        </svg>
                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                                {t('referralCode')}
                            </div>
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[50px] whitespace-nowrap">
                                {profile.invitationCode}
                            </div>
                        </div>


                    </div>
                </div>
                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02 mb-[20px]">

                    <div className="inline-flex flex-col items-start gap-2.5 relative flex-[0_0_auto]">
                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                                {t('phone')}
                            </div>
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[50px] whitespace-nowrap">
                                {profile.phone}
                            </div>
                        </div>
                        <svg width="330" height="2" viewBox="0 0 330 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1H330" stroke="#E8E8E8" strokeWidth="0.5"/>
                        </svg>
                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                                {t('email')}
                            </div>
                            <div
                                className="relative w-200 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-right tracking-[0] leading-[50px] whitespace-nowrap">
                                {profile.email}
                            </div>
                        </div>
                        <svg width="330" height="2" viewBox="0 0 330 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1H330" stroke="#E8E8E8" strokeWidth="0.5"/>
                        </svg>

                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] tracking-[0] leading-[50px] whitespace-nowrap">
                                {t('changePassword')}
                            </div>
                            <img style={{transform: "rotate(-90deg)"}}
                                 className="relative w-[6.86px] h-[12.31px] ml-[-0.71px]"
                                 alt="Rectangle"
                                 src={rectangle}
                                 onClick={()=>{navigate('/ChangePassword')}}
                            />
                        </div>

                    </div>
                </div>
                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02 mb-[20px]">

                    <div className="inline-flex flex-col items-start gap-2.5 relative flex-[0_0_auto]" onClick={() => {
                        navigate('/ContactUs')
                    }}>

                        <div className="flex w-[330px] items-center justify-between relative flex-[0_0_auto]">
                            <div
                                className="relative w-20 h-10 mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px]
                                tracking-[0] leading-[50px] whitespace-nowrap">
                                {t('contactUs')}
                            </div>
                            <img style={{transform: "rotate(-90deg)"}}
                                 className="relative w-[6.86px] h-[12.31px] ml-[-0.71px]"
                                 alt="Rectangle"
                                 src={rectangle}
                            />
                        </div>

                        <div onClick={showPopup}
                            className="mt-[30px] flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)]">
                            <div
                                 className="relative w-[350px] mt-[-2.00px] ml-[-24.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center tracking-[0] leading-[50px]">
                                {t('logout')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupConfirm isOpen={isOpen} onClose={hidePopup} onConfirm={handleLogout} title={t('logoutConfirmationTitle')} msg={t('logoutConfirmationMessage')} ></PopupConfirm>
        </MainLayout>
    );
}
