import {useTranslation} from "react-i18next";

export default function Wallets({wallets}) {
    const {t, i18n} = useTranslation();


    return (

        <>  {wallets && wallets.length > 0
            ? <div className="relative w-[330px] h-[90px] flex flex-row overflow-auto gap-5">
                {
                    wallets.map((data, index) => (
                        <div key={index}
                             className="flex flex-col w-[101px] items-center justify-center gap-[15px] px-[17px] py-[13px]  bg-variable-collection-15 rounded-[10px] overflow-hidden">
                            <div
                                className="relative self-stretch mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-sm text-center tracking-[0] leading-[normal]">
                                {data.currencyName}
                            </div>
                            <div
                                className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto] ml-[-8.10px] mr-[-8.10px]">
                                <div
                                    className="relative w-[83px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] text-center tracking-[0] leading-[normal]">
                                    {t('accountBalance')}
                                </div>
                                <div
                                    className="w-[83px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-[15px] text-center leading-[normal] relative tracking-[0]">
                                    {data.balance}
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div> : <></>}</>
    )
}
