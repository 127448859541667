import React from "react";
import PopupConfirm from "./PopupConfirm";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function TransactionProcessingPopup({isOpen, onClose}) {
    if (!isOpen) return null;
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const handleClose=()=>{

        onClose();
        navigate("/dashboard")
    }
    return (
        <PopupConfirm isOpen={isOpen} onClose={handleClose} onConfirm={handleClose} title={t('requestReceivedTitle')} msg={t('requestReceivedMessage')}></PopupConfirm>
    )
}
