import React, { useEffect, useState } from 'react';
import { TargetCurrencyRate } from "./TargetCurrencyRate";
import { FromCurrencyRate } from "./FromCurrencyRate";
import {useTranslation} from "react-i18next";

export const ExchangeRateTable = ({ exchangeData }) => {
    const [currentId, setCurrentId] = useState(1);
    const [currentCurrency, setCurrentCurrency] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (exchangeData.length > 0) {
            const filteredCurrency = exchangeData.filter(x => x.currency.id === currentId);
            setCurrentCurrency(filteredCurrency);
        }
    }, [exchangeData, currentId]); // 依賴項為 exchangeData 和 currentId

    return (
        <div className="relative w-[370px] h-[471px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
            <div className="absolute w-[330px] top-[23px] left-5 [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-[15px] tracking-[0] leading-[normal]">
                {t('currentExchangeRate')}
            </div>
            <div className="absolute w-[350px] h-[400px] top-[59px] left-2.5">
                <div className="w-[209px] gap-5 absolute top-0 left-[141px] rounded-[5px] overflow-hidden overflow-x-scroll flex items-center">
                    <div className="relative w-52 h-[400px]">
                        <div className="flex-col w-52 h-[400px] gap-[5px] px-3.5 py-2.5 bg-variable-collection-01 rounded-[20px] border border-solid border-variable-collection-02 flex items-center">
                            <div className="flex w-[210px] h-10 items-center justify-between px-2.5 py-[5px] relative">
                                <div className="w-[75px] h-10 leading-[50px] relative mt-[-6.00px] mb-[-4.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] tracking-[0] whitespace-nowrap">
                                    {t('toCurrency')}
                                </div>
                                <div className="w-fit text-right leading-10 relative mt-[-6.00px] mb-[-4.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] tracking-[0] whitespace-nowrap">
                                    {t('exchangeRate')}
                                </div>
                            </div>
                            <svg width="180" height="2" viewBox="0 0 180 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 1H180" stroke="#62554E" strokeWidth="0.5"/>
                            </svg>
                            {currentCurrency.length > 0 && currentCurrency[0].exchangeRates.map((data, index) => (
                                <TargetCurrencyRate
                                    key={index}
                                    className="!mr-[-15.00px] !ml-[-15.00px] !w-[210px]"
                                    divClassName="!ml-[-4.5px]"
                                    property1="default"
                                    code={data.toCurrencyCode}
                                    rate={data.rate}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    className="absolute w-[129px] h-[450px] top-0 left-0 [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center tracking-[0] leading-[50px] whitespace-nowrap">
                    <div
                        className="flex-col w-[100px] justify-between px-5 py-[5px] relative  top-0 left-3.5 flex items-center mb-2">
                        <div className="w-[94px]  text-[15px] text-center text-variable-collection-05">
                            {t('fromCurrency')}
                        </div>
                        <svg width="91" height="2" viewBox="0 0 91 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 1H90.5" stroke="#62554E" strokeWidth="0.5"/>
                        </svg>

                    </div>

                    <div className="gap-2">
                        {exchangeData.length > 0 && exchangeData.map((data, index) => (
                            <FromCurrencyRate
                                key={`${data.currency.id}-${currentId}`}
                                property1={data.currency.id === currentId ? "variant-2" : "default"}
                                text={data.currency.name}
                                onClick={() => {
                                    setCurrentId(data.currency.id)
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
