import {useNavigate} from "react-router-dom";
import LandingLayout from "../components/LandingLayout";
import smallLogo from "../assets/logo-orange.png";
import React from "react";
import {useTranslation} from "react-i18next";

export default function VerificationSuccessPage() {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const handleClick = () => {
        navigate("/login");
    };
    return (
        <LandingLayout>
            <div
                className="min-h-screen flex items-center justify-center bg-gradient-to-b from-yellow-200 to-orange-300">
                <div className="h-[280px] px-5 py-3 bg-white rounded-[20px] border border-[#ffe7b7] flex-col justify-center items-center gap-5 inline-flex">
                    <div className="flex justify-center w-full">
                        <img src={smallLogo}
                             className="w-[78px] h-[59px]"/>
                    </div>
                    <h1 className="text-xl font-bold text-center font-['Inter']"> {t('verifySuccessTitle')}</h1>

                    <div
                        className="w-[330px] text-center text-[#61554e] text-[15px] font-normal font-['Inter'] leading-tight">{t('successMessage')}
                    </div>

                    <button onClick={handleClick}
                            type="submit"
                            className="overflow-hidden self-stretch mt-5 w-full text-[15px] font-bold text-center text-white whitespace-nowrap rounded-md border-2 border-yellow-50 border-solid shadow-sm bg-[linear-gradient(180deg,#FFD889_0%,#FF9C64_100%)] leading-[50px]"
                    >
                        {t('confirmButton')}
                    </button>


                </div>
            </div>

        </LandingLayout>
    );
}
