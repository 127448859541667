import smallLogo from "../assets/logo-orange.png";
import React, {useEffect, useState} from "react";
import rectangle from "../assets/Rectangle 7.svg"
import MainLayout from "../components/MainLayout";
import {checkName, getWallets, transfer} from "../services/apiService";
import {usePopup} from "../services/PopupContext";
import TransactionProcessingPopup from "../components/TransactionProcessingPopup";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Wallets from "../components/wallets";

export default function TransferPage() {
    const [wallets, setWallets] = useState([]);
    const [amount, setAmount] = useState('');
    const [currencyId, setCurrencyId] = useState('');
    const [remark, setRemark] = useState('');
    const [accountInfo, setAccountInfo] = useState('');
    const {isOpen, showPopup, hidePopup} = usePopup();
    const [loading, setLoading] = useState(false);
    const [checkNameLoading, setCheckNameLoading] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [targetNameError, setTargetNameError] = useState('');


    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const wallets = await getWallets();

                if (wallets.length > 0) {
                    setWallets(wallets);
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };

        fetchBalance();

    }, [])
    const handleCheckName = async (e) => {
        console.log("handleCheckName")
        e.preventDefault();
        setError(''); // Clear the error if inputs are valid

        if (accountInfo) {
            setCheckNameLoading(true);

            const resp = await checkName(accountInfo);
            if (resp.status === 400) {
                setTargetNameError(t(resp.title));
            } else if (resp.ok) {
                resp.json().then((result) => {
                    if(result === true){
                        setTargetNameError('0');
                    }
                    else
                    {
                        setTargetNameError(t('UserNotFound'));
                    }
                })
            } else {
                setTargetNameError(t('systemError'));
            }
            setCheckNameLoading(false);

        } else {
            setTargetNameError(t('fillAllFields'));
        }
    };

    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        setError(''); // Clear the error if inputs are valid

        const wallet = wallets.find(x => x.currencyId == currencyId)
        if (currencyId && amount && amount > 0 && wallet && wallet.balance >= amount) {
            const type = currencyId == 1 ? 0 : 1
            setLoading(true);

            const resp = await transfer(currencyId, amount, accountInfo);
            if (resp.status === 400) {
                setError(t(resp.title));
            } else if (resp) {
                showPopup();
            } else {
                setError(t('systemError'));
            }
            setLoading(false);

        } else {
            setError(t('fillAllFields'));
        }
    };
    return (

        <MainLayout>
            <div
                className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="h-auto"/>
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                    {t('transfer')}
                </div>
                <Wallets wallets={wallets}></Wallets>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[350px] h-[35px] mt-[-1.00px] mr-[-20.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('choseCurrency')}
                    </div>
                    <div
                        className="flex w-[330px] items-center justify-between py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">
                        <select
                            className="border-none w-[320px] h-full appearance-none outline-none bg-transparent cursor-pointer text-variable-collection-05"
                            style={{
                                fontFamily: 'Inter-Regular, Helvetica',
                                fontSize: '15px',
                                textAlign: 'center',
                                lineHeight: '50px'
                            }}
                            defaultValue="" value={currencyId} onChange={(e) => {
                            setCurrencyId(e.target.value)
                        }}>
                            <option value="" disabled> {t('choseCurrencyPlaceholder')}</option>
                            {
                                wallets.map((data, index) => (
                                    <option key={index} value={data.currencyId} onClick={() => {
                                    }}>{data.currencyName} </option>
                                ))
                            }

                        </select>
                        <img
                            className="relative w-[6.86px] h-[12.31px] mr-[10px]"
                            alt="Rectangle"
                            src={rectangle}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[350px] h-[35px] mt-[-1.00px] mr-[-20.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('withdrawAmount')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('withdrawAmountPlaceholder')}
                        />
                    </div>
                </div>


                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">

                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('platformUsername')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="text"
                            value={accountInfo}
                            onChange={(e) => setAccountInfo(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('platformUsernamePlaceholder')}
                        />
                        <div onClick={checkNameLoading ? null : handleCheckName}
                             className={`flex w-40 items-center justify-center gap-2.5 px-3.5 py-0 relative rounded-[5px] overflow-hidden border border-solid border-variable-collection-04  ${
                                 checkNameLoading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                            <div
                                className="w-40 mt-[-1.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-04 text-[15px] text-center leading-[50px] relative tracking-[0]">
                                {t('checkUsername')}
                            </div>
                        </div>
                    </div>

                </div>
                {targetNameError &&
                    <div className ={`border ${targetNameError === '0'? "bg-green-100 text-black-700 ":"bg-red-100 text-red-700"} border-red-400  px-4 py-3 rounded relative`}
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{targetNameError === '0'? t('usernameCorrect'):targetNameError}</span>

                    </div>}

                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('remark')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="text"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('remarkPlaceholder')}
                        />
                    </div>
                </div>
                {error &&
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>

                    </div>}
                <div
                    onClick={!loading ? handleSubmit : null}  // Disable click when loading
                    className={`flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    <div
                        className="w-[330px] mt-[-2.00px] ml-[-14.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center leading-[50px] relative tracking-[0]"
                    >
                        {loading ? t('processing') : t('confirmTransfer')}
                    </div>
                </div>

                <div className="flex w-[330px] items-center justify-end gap-2.5 relative flex-[0_0_auto]">
                    <p className="w-[300px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
            <span className="text-[#61554e]">
             {t('csReminder')}&nbsp;&nbsp;
            </span>
                        <span className="text-[#ff9b63] underline" onClick={() => {
                            navigate('/ContactUs')
                        }}> {t('csLink')}</span>
                    </p>
                </div>
            </div>
            <TransactionProcessingPopup isOpen={isOpen} onClose={hidePopup}></TransactionProcessingPopup>

        </MainLayout>
    );
}
