import smallLogo from "../assets/logo-orange.png";
import React, {useEffect, useState} from "react";
import rectangle from "../assets/Rectangle 7.svg"
import MainLayout from "../components/MainLayout";
import {deposit, getCurrencies, getWallets} from "../services/apiService";
import {usePopup} from "../services/PopupContext";
import TransactionProcessingPopup from "../components/TransactionProcessingPopup";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Wallets from "../components/wallets";

export default function DepositPage() {
    const [currencyList, setCurrencyList] = React.useState([]);
    const [amount, setAmount] = useState('');
    const [currencyId, setCurrencyId] = useState('');
    const [remark, setRemark] = useState('');
    const {isOpen, showPopup, hidePopup} = usePopup();
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [wallets, setWallets] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        // 獲取匯率資料
        const fetchCurrencyList = async () => {
            try {
                const response = await getCurrencies();
                setCurrencyList(response);
            } catch (error) {
                console.error("Error fetching currencyList :", error);
            }
        };
        const fetchBalance = async () => {
            try {
                const wallets = await getWallets();

                if (wallets.length > 0) {
                    setWallets(wallets);
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };

        fetchBalance();
        fetchCurrencyList();
    }, []);
    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        setError(''); // Clear the error if inputs are valid

        if (currencyId && amount && amount > 0) {
            setLoading(true);

            const resp = await deposit(currencyId, amount, remark);
            if (resp.status === 400) {
                setError(t(resp.title));
            } else if (resp.ok) {
                showPopup();
            }
            else {
                setError(t('systemError'));

            }
            setLoading(false);
        }
        else {
            setError(t('fillAllFields'));
        }

    };

    return (

        <MainLayout>
            <div
                className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[-10.00px] mr-[-10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="w-[78px] h-[59px]"/>
                </div>
                <div
                    className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                    {t('deposit')}
                </div>
                <Wallets wallets={wallets}></Wallets>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[350px] h-[35px] mt-[-1.00px] mr-[-20.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('depositCurrency')}
                    </div>
                    <div
                        className="flex w-[330px] items-center justify-between py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">
                        <select
                            className="border-none w-[320px] h-full appearance-none outline-none bg-transparent cursor-pointer text-variable-collection-05"
                            style={{
                                fontFamily: 'Inter-Regular, Helvetica',
                                fontSize: '15px',
                                textAlign: 'center',
                                lineHeight: '50px'
                            }}
                            defaultValue="" value={currencyId} onChange={(e) => {
                            setCurrencyId(e.target.value)
                        }}>
                            <option value="" disabled> {t('depositCurrencyPlaceholder')}</option>
                            {
                                currencyList.map((data, index) => (
                                    <option key={index} value={data.id} onClick={() => {
                                    }}>{data.name} ({data.code}) </option>
                                ))
                            }

                        </select>
                        <img
                            className="relative w-[6.86px] h-[12.31px] mr-[10px]"
                            alt="Rectangle"
                            src={rectangle}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[350px] h-[35px] mt-[-1.00px] mr-[-20.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('depositAmount')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('depositAmountPlaceholder')}
                        />
                    </div>
                </div>
                <div className="flex w-[330px] h-10 items-start justify-between relative">
                    <div
                        className="w-[200px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] leading-5 relative tracking-[0]">
                        {t('minOfDeposit')}
                        <br/>
                        {t('maxOfDeposit')}
                    </div>
                    <div
                        className="w-[92px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
                        1,000
                        <br/>
                        1,000,000
                    </div>
                </div>
                <div className="flex flex-col w-[330px] items-start gap-[5px] relative flex-[0_0_auto]">
                    <div
                        className="w-[330px] h-[35px] mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-variable-collection-05 text-[15px] leading-[50px] whitespace-nowrap relative tracking-[0]">
                        {t('remark')}
                    </div>
                    <div
                        className="flex w-[330px] items-center gap-2.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden border border-solid border-[#e8e8e8]">

                        <input
                            type="text"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            className="border-none w-full [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-center leading-[50px] whitespace-nowrap relative tracking-[0]"
                            placeholder={t('remarkPlaceholder')}
                        />
                    </div>
                </div>
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>
                    </div>
                )}
                <div onClick={!loading ? handleSubmit : null}
                     className={`flex w-[330px] items-center justify-center gap-2.5 px-3.5 py-0 relative flex-[0_0_auto] rounded-[5px] overflow-hidden shadow-[-2px_2px_10px_#0000004c] [background:linear-gradient(180deg,rgb(255,216,137)_0%,rgb(255,155.72,99.88)_100%)] ${
                         loading ? 'opacity-50 cursor-not-allowed' : ''
                     }`}>
                    <div
                        className="relative w-[350px] mt-[-2.00px] ml-[-24.00px] mr-[-14.00px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-[15px] text-center tracking-[0] leading-[50px]">
                        {loading ? t('processing') : t('depositConfirm')}
                    </div>
                </div>
                <div className="flex w-[330px] items-center justify-end gap-2.5 relative flex-[0_0_auto]">
                    <p className="w-[300px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-variable-collection-05 text-[15px] text-right leading-5 relative tracking-[0]">
            <span className="text-[#61554e]">
             {t('csReminder')}&nbsp;&nbsp;
            </span>
                        <span className="text-[#ff9b63] underline" onClick={() => {
                            navigate('/ContactUs')
                        }}> {t('csLink')}</span>
                    </p>
                </div>
            </div>
            <TransactionProcessingPopup isOpen={isOpen} onClose={hidePopup}></TransactionProcessingPopup>
        </MainLayout>
    );
}
