import React, { useState } from 'react';
import {useTranslation} from "react-i18next"; // Update with the correct path
import langIcon from "../assets/globe.png"
const LanguageSetting = () => {
    const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close

    const toggleMenu = () => {
        console.log("menuOpen", menuOpen)
        setMenuOpen(!menuOpen); // Toggle the menu open/close state
    };
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        toggleMenu(); // Close menu after selecting a language
    };
    return (
        <div className="absolute top-2 right-7 z-[9999]  py-2 px-4">

            <img onClick={toggleMenu}
                className="absolute w-10 h-10  z-9999"
                alt="Mingcute menu line"
                src={langIcon}
            />

            {menuOpen && (
                <div className="absolute right-0 top-[55px]">
                    <div className="bg-white shadow-lg rounded-lg py-2 w-35">
                        <ul>
                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                <div onClick={() => changeLanguage('en')}
                                     className="[font-family:'Inter-Regular',Helvetica] w-[50px] mt-[-1.00px] tracking-[0] text-[14px] text-variable-collection-05 h-7 font-normal leading-[normal] relative">
                                    English
                                </div>
                            </li>
                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                <div onClick={() => changeLanguage('zn')}
                                     className="[font-family:'Inter-Regular',Helvetica] w-[50px] mt-[-1.00px] tracking-[0] text-[14px] text-variable-collection-05 h-7 font-normal leading-[normal] relative">
                                    中文
                                </div>
                            </li>
                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">

                                <div onClick={() => changeLanguage('jp')}
                                     className="[font-family:'Inter-Regular',Helvetica] w-[50px] mt-[-1.00px] tracking-[0] text-[14px] text-variable-collection-05 h-7 font-normal leading-[normal] relative">
                                    日本語
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguageSetting;
