import {useNavigate} from "react-router-dom";
import LandingLayout from "../components/LandingLayout";
import smallLogo from "../assets/logo-orange.png";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {verifyEmail, verifyPhone} from "../services/apiService";
import {useAuth} from "../services/authContext";

export default function EmailVerificationPage() {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [error, setError] = useState('');
    const {userName} = useAuth();
    const [code, setCode] = useState('');

    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        setError(''); // Clear the error if inputs are valid

        const resp = await verifyEmail(userName, code);
        if (resp.status === 400) {
            setError(t(resp.title));
        } else if (resp.ok) {
            navigate("/verifySuccess");
        }
        else {
            setError(t('systemError'));
        }
    };
    return (
        <LandingLayout>
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-sm">
                <div className="flex justify-center w-full">
                    <img src={smallLogo}
                         className="w-[78px] h-[59px]"/>
                </div>
                <h1 className="mt-5 text-xl font-bold text-center font-['Inter']">{t('emailTitle')}</h1>

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('otpCode')}</label>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-orange-300"
                            placeholder="請檢查您的信箱，並輸入驗證碼"
                        />
                        <p className="w-full mt-1.5 leading-5 text-right text-[15px]">
                            {t('noOtpReceived')}<a href="#" className="text-orange-500">{t('resendOtp')}</a>
                        </p>
                    </div>
                    {error &&
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                             role="alert">
                            <span className="block sm:inline [font-family:'Inter-Medium',Helvetica]">{error}</span>

                        </div>}
                    <button
                        type="submit"
                        className="overflow-hidden gap-2.5 self-stretch mt-5 w-full text-[15px] font-bold text-center text-white whitespace-nowrap rounded-md border-2 border-yellow-50 border-solid shadow-sm bg-[linear-gradient(180deg,#FFD889_0%,#FF9C64_100%)] leading-[50px]"
                    >
                        {t('confirmButton')}
                    </button>
                </form>


            </div>

        </LandingLayout>
    );
}
