import smallLogo from "../assets/logo-orange.png";
import filter from "../assets/filter.png";
import React, {useEffect, useState} from "react";
import MainLayout from "../components/MainLayout";
import {getTransactions, getWallets} from "../services/apiService";
import {useTranslation} from "react-i18next";
import Wallets from "../components/wallets";
import dayjs from "dayjs";
import vector from "../assets/Vector 2.png";
import {TransactionFilter} from "../components/TransactionFilter";

export default function TransactionsPage() {
    const {t, i18n} = useTranslation();
    const [wallets, setWallets] = useState([]);
    const [groupedByMonth, setGroupedByMonth] = useState([]);
    const [months, setMonths] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterPayload, setFilterPayload] = useState({
        status: [],
        fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        toDate: new Date(),
    });

    useEffect(() => {
        const fetchTransaction = async () => {
            try {
                setLoading(true);
                const trans = await getTransactions(filterPayload);

                if (trans.length > 0) {

                    const groupedData = transformData(trans);
                    setGroupedByMonth(groupedData);
                } else {
                    setMonths([])
                    setGroupedByMonth([])
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
            finally {
                setLoading(false);
            }
        };

        const transformData = (apiData) => {
            const newMonths = []; // Use a temporary array to collect new months
            const grouped = apiData.reduce((acc, transaction) => {
                const dateKey = transaction.createdTime.slice(0, 7); // Extract YYYY-MM
                if (!acc[dateKey]) {
                    acc[dateKey] = [];
                }
                acc[dateKey].push(transaction);
                if (!newMonths.includes(dateKey)) {
                    newMonths.push(dateKey); // Add to temporary array instead of state
                }
                return acc;
            }, {});

            setMonths(newMonths); // Update the state once with all new months

            // Convert to array format
            return Object.entries(grouped).map(([date, transactions]) => ({
                date,
                transactions,
            }));
        }


        fetchTransaction();
    }, [filterPayload]);

    useEffect(() => {
        const fetchWallets = async () => {
            try {
                const wallets = await getWallets();

                if (wallets.length > 0) {
                    setWallets(wallets);
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };
        fetchWallets();
    }, [])

    return (

        <MainLayout>
            <div className="inline-flex flex-col items-center px-5 py-3 relative">
                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02 mb-[20px]">
                    <div className="flex justify-center w-full">
                        <img src={smallLogo}
                             className="w-[78px] h-[59px]"/>
                    </div>
                    <div
                        className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-xl text-center leading-[normal] relative tracking-[0]">
                        {t('transactionHistory')}

                    </div>
                    <Wallets wallets={wallets}></Wallets>
                </div>
                <div
                    className="inline-flex flex-col items-center gap-5 px-5 py-3 relative ml-[10.00px] mr-[10.00px] bg-white rounded-[20px] border border-solid border-variable-collection-02">
                    <div className="mb-4 mt-2 w-full flex justify-end" onClick={() => setFilterOpen(true)}>
                        <img src={filter}/>
                    </div>
                    {loading ? <div
                        className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-m text-center leading-[normal] relative tracking-[0]">
                        {t('loading')}

                    </div> : groupedByMonth.length > 0 ? <>

                        {
                            months.map((month, key) => (
                                <div key={key} className="flex w-[330px] items-start justify-center gap-5 relative">
                                    <div className="relative w-[39px] ">
                                        <div className="relative w-[35px] ">
                                            <div
                                                className="absolute w-[35px] h-[15px] top-0 left-0 [font-family:'Inter-Medium',Helvetica] font-bold text-xs text-center tracking-[0] leading-[normal] text-variable-collection-05">
                                                {month.split('-')[0]}
                                            </div>
                                            <div
                                                className="absolute w-[35px] h-6 top-[13px] left-0 [font-family:'Inter-Medium',Helvetica] font-bold text-xl text-center tracking-[0] leading-[normal] text-variable-collection-05">
                                                {month.split('-')[1]}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-[270px] items-end gap-2.5 relative">
                                        {
                                            groupedByMonth
                                                .find((x) => x.date === month)?.transactions
                                                .map((data, index) => (
                                                    <div key={index}
                                                         className="flex-col w-[270px] flex items-end gap-2.5 relative">
                                                        <div
                                                            className="flex w-[270px] items-center justify-between relative">
                                                            <div
                                                                className="w-[172.5px] flex items-center gap-2.5 relative">
                                                                <div
                                                                    className="relative w-[15px] h-[15px] bg-variable-collection-03 rounded-[7.5px]"/>
                                                                <div
                                                                    className="relative w-[100px] [font-family:'Inter-Medium',Helvetica] font-bold text-xs tracking-[0] leading-[normal] text-variable-collection-05">
                                                                    {t(data.transactionType)}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="relative w-[100px] [font-family:'Inter-Medium',Helvetica] font-bold text-xs text-right tracking-[0] leading-[normal] text-variable-collection-05">
                                                                {data.amount} {data.currencyCode}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex w-[270px] items-center justify-between relative">
                                                            <div
                                                                className="relative w-[100px] [font-family:'Inter-Medium',Helvetica] font-medium text-[10px] tracking-[0] leading-[normal] text-variable-collection-05">
                                                                {dayjs(data.updatedTime).format('YYYY-MM-DD HH:mm:ss')}
                                                            </div>
                                                            <p className="relative w-[126px] [font-family:'Inter-Medium',Helvetica] font-normal text-[10px] text-right tracking-[0] leading-[normal] text-variable-collection-05">
                                                                {data.status}
                                                            </p>
                                                        </div>
                                                        <img src={vector}/>
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            ))
                        }
                    </> : <div
                        className="w-[330px] [font-family:'Inter-Bold',Helvetica] font-bold text-variable-collection-05 text-m text-center leading-[normal] relative tracking-[0]">
                        {t('noData')}

                    </div>
                    }

                </div>
            </div>
            <TransactionFilter isOpen={filterOpen} onClose={() => setFilterOpen(false)} filterPayload={filterPayload}
                               handleSubmit={(payload) => {
                                   setFilterOpen(false);
                                   setFilterPayload(payload)
                               }}></TransactionFilter>
        </MainLayout>
    );
}
